export let portfolioData1 = [
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio1.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio2.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio3.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio4.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio5.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio6.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio1.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio2.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio3.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio4.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio5.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio6.png" },
];

export let portfolioData2 = [
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio7.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio8.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio9.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio10.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio11.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio12.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio7.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio8.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio9.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio10.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio11.png" },
  { src: "https://www.eduedu.kr/theme/edu/img/portfolio12.png" },
];
